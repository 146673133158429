import React, { useCallback, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import axios from "axios";

export default function UploadAgreementModal({
  toggle,
  data: client,
  cancel_before,
}) {
  const [loading, setLoading] = useState(false);
  const [doc, setDOc] = useState(null);

  const onDrop = useCallback(async (acceptedFiles) => {
    setLoading(true);
    let fd = new FormData();
    fd.append("file", acceptedFiles);
    await axios
      .post(process.env.REACT_APP_API_URL + "uploads/agreement", fd)
      .then((res) => {
        setDOc(res.data);
        toast.success("Successfully uploaded");
      })
      .catch((error) => toast.error(error.toString()))
      .finally(() => {
        setLoading(false);
        // toggle();
      });
  }, []);

  const handleChange = (file) => {
    onDrop(file);
  };

  const handleSave = async () => {
    try {
      if (doc === null) {
        toast.error("Need to upload agreement");
      } else {
        await axios.post(process.env.REACT_APP_API_URL + "agreements", {
          lead: client?.lead?._id,
          doc,
          client: client?._id,
          cancel_before,
        });
        toast.success("successfully signed");
        toggle();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold">
                Upload Agreement Document
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={toggle}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="relative px-6 flex-auto">
              {loading ? (
                <span>uploading...</span>
              ) : (
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  // types={fileTypes}
                />
              )}
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={toggle}
              >
                Close
              </button>
              <button
                className="bg-gray-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
