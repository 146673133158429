import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLead } from "../../redux/leads/lead.slices";
import CreditCardForm from "../../components/CreditCardForm";
import AchForm from "../../components/AchForm";
import SelectMethodBtn from "../../components/SelectMethodBtn";

export default function PaymentHome() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("credit");
  const [proratedCharges, setproratedCharges] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const dispatch = useDispatch();
  const { id } = useParams();

  const { isLoading, lead } = useSelector((state) => state.leads);

  useEffect(() => {
    dispatch(getLead(id));
  }, [dispatch, id]);

  const totalOnetimeFees = lead?.onetime_fees?.reduce(
    (acc, curr) => (acc += parseFloat(curr?.feeAmount)),
    0
  );

  useEffect(() => {
    let today = new Date();
    let todayDate = today.getDate();

    if (parseInt(lead?.billing_date) < todayDate) {
      today.setMonth(today.getMonth() + 1);
      today.setDate(parseInt(lead?.billing_date));
    } else {
      today.setDate(lead?.billing_date);
    }

    //calculating days difference
    const perdayAmount = (lead?.package?.amount / 30).toFixed(2);

    const remaining_days = Math.round(
      (today.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
    );

    const proratedAmount = perdayAmount * remaining_days;

    setproratedCharges(proratedAmount);
  }, [lead?.billing_date, lead?.package?.amount]);

  useEffect(() => {
    if (lead?.status === "Paid") {
      navigate(`/payment/${lead?._id}/completed`);
    }
  }, []);

  return (
    <>
      <section className="border min-h-1/2 w-screen rounded-md lg:w-1/2 mx-auto m-10">
        <div className="py-6 flex flex-col justify-center items-center rounded-t-md bg-gradient-to-r from-[#021521] to-[#083757]">
          <h1 className="text-white text-center text-xl px-4">
            Complete Payment
          </h1>
          <p className="text-gray-500 mt-4">
            Package Name:{" "}
            <span className="text-yellow-400">{lead?.package?.name}</span>
          </p>
          <p className="text-gray-500">
            Package Amount:{" "}
            <span className="text-white">
              ${lead?.package?.amount?.toFixed(2)}
            </span>
          </p>
          <hr className="my-2 text-yellow-400 w-1/2" />
          <p className="text-gray-500">
            Onetime Fee: <span className="text-white">${totalOnetimeFees}</span>
          </p>
          <hr className="my-2 text-yellow-400 w-1/2" />
          <p className="text-gray-500">
            Prorated Amount:{" "}
            <span className="text-white">${proratedCharges.toFixed(2)}</span>
          </p>
          <hr className="my-2 text-yellow-400 w-1/2" />
          <p className="text-red-500">
            Current Charges:{" "}
            <span className="text-white">
              $
              {Number(proratedCharges) <= 0
                ? (totalOnetimeFees + lead?.package?.amount).toFixed(2)
                : (totalOnetimeFees + proratedCharges).toFixed(2)}{" "}
            </span>
          </p>
        </div>
        {isLoading ? (
          <div className="p-6 flex items-center justify-center">
            <div className="text-black">Loading...</div>
          </div>
        ) : (
          <div>
            {lead?.paymentMethod === "both" &&
            Math.round(proratedCharges + totalOnetimeFees) > 0 ? (
              <div className="p-6 flex flex-col justify-center">
                <div className="flex flex-col rounded-md w-full p-6 mx-auto lg:w-2/3 gap-2 sm:flex-row">
                  <SelectMethodBtn
                    text={"Credit or Debit Card"}
                    selectedOption={selectedOption}
                    onSelectedOption={() => setSelectedOption("credit")}
                    icon="ri-bank-card-2-line"
                    title="credit"
                    isVisible={isVisible}
                  />
                  <SelectMethodBtn
                    text={"ACH"}
                    selectedOption={selectedOption}
                    onSelectedOption={() => setSelectedOption("ACH")}
                    icon="ri-bank-line"
                    title="ACH"
                    isVisible={isVisible}
                  />
                </div>
                {selectedOption === "ACH" ? (
                  <AchForm
                    lead={lead}
                    prorated={proratedCharges}
                    setIsVisible={setIsVisible}
                  />
                ) : (
                  <CreditCardForm
                    lead={lead}
                    onSelectedOption={() => setSelectedOption("ACH")}
                    prorated={proratedCharges}
                  />
                )}
              </div>
            ) : lead?.paymentMethod === "ach" ||
              (lead?.paymentMethod === "both" &&
                Math.round(proratedCharges + totalOnetimeFees) <= 0) ? (
              <div>
                <h2 className="text-center text-2xl my-6">
                  Click on continue to subscribe with ACH
                </h2>
                <AchForm lead={lead} prorated={proratedCharges} />
              </div>
            ) : (
              <div>
                <h2 className="text-center text-2xl my-6">
                  Provide your credit card details
                </h2>
                <CreditCardForm lead={lead} prorated={proratedCharges} />
              </div>
            )}
          </div>
        )}

        {/* <div className="p-6 flex flex-col justify-center">
        <div className="flex flex-col rounded-md w-full p-6 mx-auto lg:w-2/3 gap-2 sm:flex-row">
          <SelectMethodBtn
            text={"Credit or Debit Card"}
            selectedOption={selectedOption}
            onSelectedOption={() => setSelectedOption("credit")}
            icon="ri-bank-card-2-line"
            title="credit"
          />
          <SelectMethodBtn
            text={"ACH"}
            selectedOption={selectedOption}
            onSelectedOption={() => setSelectedOption("ACH")}
            icon="ri-bank-line"
            title="ACH"
          />
        </div>
        {selectedOption === "ACH" ? <AchForm /> : <CreditCardForm />}
      </div> */}
      </section>
    </>
  );
}
