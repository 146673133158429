import { configureStore } from "@reduxjs/toolkit";
import clientSlices from "./clients/client.slices";
import leadSlice from "./leads/lead.slices";

const store = configureStore({
  reducer: {
    leads: leadSlice,
    clients: clientSlices,
  },
});

export default store;
