import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import clientService from "./client.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  clients: [],
  clients_graph: [],
  client: null,
};

//add new client
export const addClient = createAsyncThunk(
  "clients/add",
  async function (client, thunkAPI) {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await clientService.addClient(token, client);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//get an client
export const getClient = createAsyncThunk(
  "clients/details",
  async function (id, thunkAPI) {
    try {
      return await clientService.getClient(id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for clients
const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(addClient.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.client = action.payload;
      })
      .addCase(addClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      })
      .addCase(getClient.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.client = action.payload;
      })
      .addCase(getClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      });
  },
});

export const { reset } = clientSlice.actions;
export default clientSlice.reducer;
