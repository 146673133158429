import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "clients/";

// POST new client
const addClient = async (token, client) => {
  const res = await axios.post(API_URL, client, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data;
};

// GET an client
const getClient = async (id) => {
  const res = await axios.get(API_URL + id);

  return res.data.data;
};

// UPDATE an client
const updateClient = async () => {};

const clientService = {
  addClient,
  getClient,
  updateClient,
};

export default clientService;
