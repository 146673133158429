import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import leadService from "./lead.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  lead: null,
};

//get an lead
export const getLead = createAsyncThunk(
  "leads/details",
  async function (id, thunkAPI) {
    try {
      return await leadService.getLead(id);
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue({ message });
    }
  }
);

//create slice for leads
const leadSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder

      .addCase(getLead.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lead = action.payload;
      })
      .addCase(getLead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error;
      });
  },
});

export const { reset } = leadSlice.actions;
export default leadSlice.reducer;
