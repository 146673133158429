import React from "react";

export default function Header() {
  return (
    <nav className="items-center shadow-md justify-between flex-wrap bg-teal px-6 py-4 lg:flex">
      <div className="flex items-center flex-no-shrink text-white mr-6">
        <img
          src="/assets/logos/logo.png"
          alt=""
          className="h-10 mr-2"
          height="100"
        />
      </div>
      {/* <div className="block lg:hidden"></div> */}
      <div className="gap-4 sm:gap-2 flex lg:items-center">
        {/* <button
          // onClick={() => navigate("/success")}
          className="flex text-center shadow mr-auto bg-[#0E669B] hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"
        >
          <i class="ri-download-2-fill mr-2"></i>
          Download
        </button>
        <button
          // onClick={() => navigate("/success")}
          className="flex text-center shadow mr-auto ring-1 ring-[#0E669B] hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-xs py-3 px-10 rounded"
        >
          <i class="ri-upload-cloud-2-line mr-2"></i>
          Upload
        </button> */}
      </div>
    </nav>
  );
}
