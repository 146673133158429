import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

export default function ErrorScreen() {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <section className="border min-h-1/2 rounded-md lg:w-1/3 mx-auto m-10">
      <div className="py-5 flex flex-col justify-center items-center rounded-t-md bg-red-600">
        <div>
          <i className="ri-close-circle-line text-6xl font-thin text-white my-10"></i>
        </div>
        <h1 className="text-white">Transaction Not Completed</h1>
      </div>
      <div className="p-6 flex flex-col justify-center">
        <div className="mx-auto"></div>

        <div className="w-full p-6 mx-auto lg:w-2/3">
          <span className="text-gray-500 my-4">
            OOPS! Something went wrong. Please try again
          </span>

          <div
            onClick={() => navigate(`/${id}`)}
            className="block my-4 text-center mx-auto text-[#0E669B] text-md py-3 px-10 border rounded cursor-pointer"
          >
            Start Now
          </div>
        </div>
      </div>
    </section>
  );
}
