import React from "react";

export default function SelectMethodBtn({
  selectedOption,
  onSelectedOption,
  text,
  icon,
  isVisible,
  title,
}) {
  return (
    <button
      className={`text-center py-2 px-4 ring-1 rounded-md flex justify-between items-center  sm:w-full ${
        selectedOption === title
          ? "bg-[#EFFAFF] text-[#000000] ring-[#1AA7E5]"
          : "ring-gray-300"
      }`}
      onClick={onSelectedOption}
      disabled={isVisible}
    >
      <div className="flex items-center gap-1">
        <i className={`${icon} text-2xl`}></i> <span>{text}</span>
      </div>

      <input
        type="radio"
        name="tab"
        checked={selectedOption === title}
        disabled={isVisible}
      />
    </button>
  );
}
