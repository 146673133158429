import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SuccessAgreement() {
  const { isLoading, lead, isSuccess } = useSelector((state) => state.leads);

  return (
    <section className="border min-h-1/2 rounded-md lg:w-1/3 mx-auto m-10">
      <div className="py-5 flex flex-col justify-center items-center rounded-t-md bg-[#26BF40]">
        <div>
          <i className="ri-checkbox-circle-line text-6xl font-thin text-white my-10"></i>
        </div>
        <h1 className="text-white">Agreement Completed</h1>
        <p className="text-gray-300 mt-5">
          <span className="text-white">{lead?._id}</span>
        </p>
        <p className="text-gray-300">
          <span className="text-white">
            Thank you for completing this document
          </span>
        </p>
      </div>
      {/* <div className="p-6 flex flex-col justify-center">
        <div className="mx-auto">
          <p className="text-gray-500 my-4">
            Transaction Date: <span className="text-black">01/02/2023</span>
          </p>
          <p className="text-gray-500 my-4">
            Transaction Amount: <span className="text-black">$50.00</span>
          </p>
          <p className="text-gray-500 my-4">
            Confirmation Number: <span className="text-black">422345861</span>
          </p>
        </div>

        <div className="w-full p-6 mx-auto lg:w-2/3">
          <span className="text-gray-500 my-4">
            This message confirms that on January 18th, 2023 a transaction of
            $50.00 has been completed and the Confirmation Number is 12345678.
          </span>

          <Link
            to={"/" + lead?._id}
            className="block my-4 text-center uppercase mx-auto bg-[#ffffff] hover:bg-[#ffffff] focus:shadow-outline focus:outline-none text-[#0E669B] text-xs py-3 px-10 rounded"
          >
            Go Home
          </Link>
        </div>
      </div> */}
    </section>
  );
}
