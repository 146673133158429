// import React from "react";
import SignatureCanvas from "react-signature-canvas";

// export default function Signature({ isOpen, toggle, sigCanvas, clear, save }) {
//   return (
//     <>
//       <Popup
//         nested
//         modal
//         open={isOpen}
//         className="w-screen bg-red-400"
//         position="right center"
//       >
//         <div className="flex justify-between items-center">
//           <span className="text-red-700">
//             NB: rotate device on smaller screens
//           </span>
//           <div onClick={toggle}>
//             <i className="ri-close-line"></i>
//           </div>
//         </div>

//         <SignatureCanvas
//           penColor="blue"
//           ref={sigCanvas}
//           canvasProps={{
//             className: "signatureCanvas ring-1 ring-gray-400",
//             placeholder: "please sign here",
//           }}
//         />

//         <div className="flex justify-between m-6">
//           <button
//             className="block text-center shadow ring w-screen ring-[#0E669B] text-[#0E669B]
//                       hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-xs py-3 px-10 rounded"
//             onClick={clear}
//           >
//             Reset
//           </button>
//           <button
//             className="block text-center shadow bg-[#0E669B]
//                       hover:bg-[#0E669B] focus:shadow-outline focus:outline-none
//                       text-white text-xs py-3 px-10 rounded"
//             onClick={save}
//           >
//             Adopt
//           </button>
//         </div>
//       </Popup>
//     </>
//   );
// }
import React, { useState } from "react";

const Modal = ({ isOpen, toggle, sigCanvas, clear, save }) => {
  return (
    <>
      {isOpen && (
        <div className="fixed z-10 inset-0 overflow-auto">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="flex justify-between items-center">
                <span className="text-red-700">
                  NB: rotate device on smaller screens
                </span>
                <div onClick={toggle}>
                  <i className="ri-close-line"></i>
                </div>
              </div>

              <SignatureCanvas
                penColor="blue"
                ref={sigCanvas}
                canvasProps={{
                  className: "signatureCanvas ring-1 ring-gray-400",
                  placeholder: "please sign here",
                }}
              />
              <div className="flex justify-between m-6">
                <button
                  className="block text-center shadow ring ring-[#0E669B] text-[#0E669B]
                      hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-xs py-3 px-10 rounded"
                  onClick={clear}
                >
                  Reset
                </button>
                <button
                  className="block text-center shadow bg-[#0E669B]
                      hover:bg-[#0E669B] focus:shadow-outline focus:outline-none
                      text-white text-xs py-3 px-10 rounded"
                  onClick={save}
                >
                  Adopt
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
