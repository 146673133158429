import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL + "leads/";

// GET an Lead
const getLead = async (id) => {
  const res = await axios.get(API_URL + id);
  return res.data.data;
};

const LeadService = {
  getLead,
};

export default LeadService;
