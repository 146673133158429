import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getLead } from "../../redux/leads/lead.slices";
import axios from "axios";
import { toast } from "react-toastify";

export default function SuccessScreen() {
  const dispatch = useDispatch();

  const { id } = useParams();
  const location = useLocation();
  // const [lead, setLead] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);

  const { isLoading, lead } = useSelector((state) => state.leads);

  useEffect(() => {
    dispatch(getLead(id));
  }, []);

  // useEffect(() => {
  //   const confirmData = async () => {
  //     setIsLoading(true);
  //     await axios
  //       .get(process.env.REACT_APP_API_URL + "leads/" + id)
  //       .then(async ({ data }) => {
  //         setLead((prev) => data.data);

  //         let leadData = data.data;
  //         if (
  //           leadData &&
  //           leadData.paymentMethod !== "card" &&
  //           leadData.status === "Payment Awaiting"
  //         ) {
  //           const totalOnetimeFees = leadData?.onetime_fees?.reduce(
  //             (acc, curr) => (acc += parseFloat(curr.feeAmount)),
  //             0
  //           );
  //           const queryParams = new URLSearchParams(location.search);

  //           const customer = queryParams.get("customer");
  //           const initial = queryParams.get("initial");

  //           await axios.post(
  //             process.env.REACT_APP_API_URL + "stripes/ach/subscribe",
  //             {
  //               status: "Pending agreement",
  //               lead: leadData?._id,
  //               manager: leadData?.manager?._id,
  //               customer,
  //               initial_payment: initial,
  //               leadData_name: leadData?.firstname + " " + leadData?.lastname,
  //               email: leadData?.email,
  //               priceID: leadData?.package?.price_id,
  //               selected_method: leadData?.paymentMethod,
  //               one_time_fees: totalOnetimeFees,
  //               contract_length: leadData?.contract_length,
  //               recurring_date: leadData?.billing_date,
  //               name: leadData?.package?.name,
  //               amount: leadData?.package?.amount,
  //               prorated: initial,
  //             }
  //           );
  //         }
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   };
  //   confirmData();

  //   return () => {};
  // }, []);

  const confirmData = async () => {
    if (
      lead &&
      lead.paymentMethod !== "card" &&
      lead.status === "Payment Awaiting"
    ) {
      const totalOnetimeFees = lead?.onetime_fees?.reduce(
        (acc, curr) => (acc += parseFloat(curr.feeAmount)),
        0
      );
      const queryParams = new URLSearchParams(location.search);

      const customer = queryParams.get("customer");
      const initial = queryParams.get("initial");

      await axios
        .post(process.env.REACT_APP_API_URL + "stripes/ach/subscribe", {
          status: "Pending agreement",
          lead: lead?._id,
          manager: lead?.manager?._id,
          customer,
          initial_payment: initial,
          lead_name: lead?.firstname + " " + lead?.lastname,
          email: lead?.email,
          priceID: lead?.package?.price_id,
          selected_method: lead?.paymentMethod,
          one_time_fees: totalOnetimeFees,
          contract_length: lead?.contract_length,
          recurring_date: lead?.billing_date,
          name: lead?.package?.name,
          amount: lead?.package?.amount,
          prorated: initial,
        })
        .then(() => {
          toast.success("Confirmed");
        })
        .catch((error) => {
          let msg =
            error?.response?.data?.error?.message?.toString() ||
            error?.response?.data?.message?.toString() ||
            error?.response?.data?.error?.toString();
          toast.error(msg);
        });
    }
  };

  const totalOnetimeFees = lead?.onetime_fees?.reduce(
    (acc, curr) => (acc += parseFloat(curr.feeAmount)),
    0
  );

  let today = new Date();
  let todayDate = today.getDate();

  if (parseInt(lead?.billing_date) < todayDate) {
    today.setMonth(today.getMonth() + 1);
    today.setDate(parseInt(lead?.billing_date));
  } else {
    today.setDate(lead?.billing_date);
  }

  //calculating days difference
  const perdayAmount = (lead?.package?.amount / 30).toFixed(2);

  const remaining_days = moment(today).diff(moment(), "days");

  const proratedAmount = perdayAmount * remaining_days;

  if (isLoading)
    return <h1 className="text-center w-full my-20">Loading...</h1>;

  return (
    <section className="border min-h-1/2 rounded-md lg:w-1/3 mx-auto m-10">
      <div className="py-5 flex flex-col justify-center items-center rounded-t-md bg-[#26BF40]">
        <div>
          <i className="ri-checkbox-circle-line text-6xl font-thin text-white my-10"></i>
        </div>
        <h1 className="text-white">Transaction Completed</h1>
        <p className="text-gray-300">
          Package: <span className="text-white">{lead?.package?.name}</span>
        </p>
      </div>
      {isLoading ? (
        <div className="p-6 flex flex-col justify-center">
          please wait, processing your data...
        </div>
      ) : (
        <div className="p-6 flex flex-col justify-center">
          <div className="mx-auto">
            <p className="text-gray-500 my-4">
              Transaction Date:{" "}
              <span className="text-black">
                {moment().format("YYYY/MM/DD")}
              </span>
            </p>
            <p className="text-gray-500 my-4">
              Transaction Amount:{" "}
              <span className="text-black">
                $
                {Number(proratedAmount) <= 0
                  ? (totalOnetimeFees + lead?.package?.amount).toFixed(2)
                  : (totalOnetimeFees + proratedAmount).toFixed(2)}{" "}
              </span>
            </p>
            <p className="text-gray-500 my-4">
              Confirmation Number:{" "}
              <span className="text-black">{lead?.phone}</span>
            </p>
          </div>

          <div className="w-full p-6 mx-auto lg:w-2/3">
            <span className="text-gray-500 my-4">
              This message confirms that on {moment().format("MMMM Do, YYYY")} a
              transaction of $
              {proratedAmount <= 0
                ? (totalOnetimeFees + lead?.package?.amount).toFixed(2)
                : (totalOnetimeFees + proratedAmount).toFixed(2)}{" "}
              has been completed.
            </span>

            <div className="block my-4 text-center mx-auto text-[#0E669B] text-md py-3 px-10">
              You can close this tab now
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
