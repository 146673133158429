import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getLead } from "../../redux/leads/lead.slices";
import { getClient } from "../../redux/clients/client.slices";

export default function Completed() {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getLead(id));
    dispatch(getClient(id));
  }, []);

  return (
    <section className="border min-h-1/2 rounded-md lg:w-1/3 mx-auto m-10">
      <div className="py-6 flex flex-col justify-center items-center rounded-t-md bg-gradient-to-r from-[#021521] to-[#083757]">
        <div>
          <i className="ri-checkbox-circle-line text-6xl font-thin text-white my-10"></i>
        </div>
        <h1 className="text-white">Payment Completed Already</h1>
      </div>
      <div className="p-6 flex flex-col justify-center">
        <div className="mx-auto">
          <img src="/assets/svg/complete.svg" alt="" className="h-40" />
        </div>

        <div className="w-full p-6 mx-auto lg:w-2/3">
          <div className="block my-4 text-center mx-auto text-[#0E669B] text-md py-3 px-10">
            You can close this tab now
          </div>
        </div>
      </div>
    </section>
  );
}
