// array of public holidays, replace with your own
const holidays = [
  "2023-01-01",
  "2023-04-14",
  "2023-05-29",
  "2023-06-04",
  "2023-06-19",
  "2023-09-04",
  "2023-10-09",
  "2023-11-11",
  "2023-11-23",
  "2023-12-25",
];

// function to check if a date is a weekend or public holiday
function isBusinessDay(date) {
  const dayOfWeek = date.getDay();
  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Sunday is 0, Saturday is 6
  const isHoliday = holidays.includes(date.toISOString().substr(0, 10));
  return !isWeekend && !isHoliday;
}

// function to get the next business day
function getNextBusinessDay(date) {
  const oneDayMs = 24 * 60 * 60 * 1000;
  let nextDay = new Date(date.getTime() + oneDayMs);
  while (!isBusinessDay(nextDay)) {
    nextDay = new Date(nextDay.getTime() + oneDayMs);
  }
  return nextDay;
}

// get the next three business days starting from today

export function threeBusinessWorkingDays() {
  let today = new Date();
  const businessDays = [];
  for (let i = 0; i < 3; i++) {
    today = getNextBusinessDay(today);
    businessDays.push(today);
  }

  return businessDays;
}

// console.log(businessDays);
