import React from "react";
import { useNavigate } from "react-router-dom";
import Stripe from "stripe";

const AchForm = ({ lead, prorated }) => {
  const navigate = useNavigate();

  const totalOnetimeFees = lead?.onetime_fees?.reduce(
    (acc, curr) => (acc += parseFloat(curr.feeAmount)),
    0
  );

  const encodedData = encodeURIComponent(
    JSON.stringify({
      lead_name: lead?.firstname + " " + lead?.lastname,
      email: lead?.email,
      priceID: lead?.package?.price_id,
      selected_method: lead?.paymentMethod,
      one_time_fees: totalOnetimeFees,
      contract_length: lead?.contract_length,
      recurring_date: lead?.billing_date,
      name: lead?.package?.name,
      amount: lead?.package?.amount,
      lead: lead?._id,
      prorated,
    })
  );

  const handleClick = async () => {
    // e.preventDefault();
    await fetch(
      `${process.env.REACT_APP_API_URL}stripes/ach?content=${encodedData}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    )
      .then(function (r) {
        return r.json();
      })
      .then(function (response) {
        window.location.href = response.id;
      });
  };

  return (
    <>
      <div>
        <div className="rounded-md w-full p-6 mx-auto lg:w-2/3">
          <div className="my-4 mx-auto lg:w-2/3 flex justify-between">
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="block text-center uppercase bg-white hover:bg-white focus:shadow-outline focus:outline-none text-[#0E669B] text-xs py-3 px-10 rounded"
            >
              Cancel
            </button>
            {/* 
            <form
              className="block text-center uppercase shadow bg-[#0E669B] hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"
              action={`/api/v1/stripes/ach?content=${encodedData}`}
              method="POST"
            > */}
            <button
              onClick={handleClick}
              className="block text-center uppercase shadow bg-[#0E669B] hover:bg-[#0E669B] focus:shadow-outline focus:outline-none text-white text-xs py-3 px-10 rounded"
            >
              Continue to Subscribe
            </button>
            {/* </form> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AchForm;
